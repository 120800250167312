import React from "react";
import { Routes, Route, useParams } from "react-router-dom";

import App from "./pages/App";
import About from "./pages/About";
import Products from "./pages/Products";
import Career from "./pages/Career";
import ProductDetails from "./pages/ProductDetails";
import Service from "./pages/Service";
import Contact from "./pages/Kontakt";
import ImpressumPage from "./pages/Impressum";
import ProductCategory from "./pages/ProductCategory";
import NotFound from "./pages/NotFound";
import Menu from "./pages/Menu";
import Footer from "./pages/Footer";
import SuccessSend from "./pages/emailsuccess";
import FailSend from "./pages/emailfailed";
import DataPrivacy from "./pages/DataPrivacy";
import { LanguageContext } from "./hooks/useLanguage";
// import ReactGA from "react-ga";

// import React, { Component } from "react";
// import { withRouter } from "react-router-dom";
// class ScrollToTop extends Component {
//   componentDidUpdate(prevProps) {
//     if (this.props.location.pathname !== prevProps.location.pathname) {
//       window.scrollTo(0, 0);
//     }
//     if (this.props.location.hash !== prevProps.location.hash) {
//       window.scrollTo(0, 0);
//     }
//   }
//   render() {
//     return <div>{this.props.children}</div>;
//   }
// }
// export default withRouter(ScrollToTop);

// function useGA() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     if (process.env.TRACKING_GA_CODE) {
//       ReactGA.initialize(process.env.TRACKING_GA_CODE);
//       // The tracking code in Google Analytics must be entered here ( process.env.TRACKING_GA_CODE ).
//     }
//   }, []);

//   useEffect(() => {
//     // window.scrollTo(0, 0);
//     if (process.env.TRACKING_GA_CODE) {
//       ReactGA.pageview(pathname);
//     }
//   }, [pathname]);
// }

export default function AppRoutes() {
  // useGA();
  const { lang } = useParams();

  return (
    <LanguageContext.Provider value={lang}>
      <Menu />
      <Routes>
        <Route exact name="emailfailed" path="emailfailed" element={<FailSend />} />
        <Route exact name="emailsuccess" path="emailsuccess" element={<SuccessSend />} />
        <Route exact path="" element={<App />} />
        <Route exact path="/" element={<App />} />
        <Route path="home" element={<App />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route exact path="products" element={<Products />} />
        <Route exact path="product/:productId" element={<ProductDetails />} />
        <Route path="career" element={<Career />} />
        <Route path="impressum" element={<ImpressumPage />} />
        <Route path="terms" element={<DataPrivacy />} />
        <Route path="services" element={<Service />} />
        <Route exact path="category/:category/" element={<ProductCategory />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      {/* <CookieConsent hideOnAccept buttonText={"Einverstanden"} expires={150}>
          Um unsere Webseite für Sie optimal zu gestalten und fortlaufend
          verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung
          der Webseite stimmen Sie der Verwendung von Cookies zu.
        </CookieConsent> */}
    </LanguageContext.Provider>
  );
}
