import React, { useMemo, useState, useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useLanguage from "../hooks/useLanguage";
import filterInvert from "../images/filter-icon-invert.svg";
import filterIcon from "../images/filter-icon.svg";
import reset from "../images/cancel.png";
import ProductItem from "./ProductItem";

import productsList from "../productsList";

function debounceFunction(func, delay) {
  let timer;
  return function () {
    let self = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(self, args);
    }, delay);
  };
};


function filterProductsList(searchValue, filterCurrentvalue) {
  if (!searchValue && !filterCurrentvalue) {
    return productsList
  }

  const searchString = searchValue.trim().toLowerCase();

  const products = productsList.filter((item) => {
    return (
      (item.product_name &&
        item.product_name.toLowerCase().includes(searchString)) ||
      (item.internetbeschreibung &&
        item.internetbeschreibung.toLowerCase().includes(searchString)) ||
      (item.internetbeschreibung_en &&
        item.internetbeschreibung_en.toLowerCase().includes(searchString))
    );
  });

  //const products_by_language = products.filter(function (e) {
  //return e.sprache === lang;
  //});*/
  const products_by_language = products
  // .filter(function (e) {
  //   return e.internetbeschreibung !== undefined;
  // });

  return products_by_language.filter((product) => {
    if (filterCurrentvalue.length === 0) {
      return true;
    }
    const productCategorie = product.applicationfields.map(
      (category) => category.label || category.productfiltermatcherid
    );
    const matchFilter = filterCurrentvalue.find(
      (filter) => productCategorie.indexOf(filter) !== -1
    );
    return !!matchFilter;
  });
}

function filterProductsByCategory(searchValue, categories) {
  const productCategories = [];

  if (categories.length === 0) {
    return productCategories;
  }

  categories.forEach((category) => {
    const subcategoriesProductsCount = [];
    category.subcategories.forEach((ref) => {
      const num = filterProductsList(searchValue, [ref.filter_text]);
      subcategoriesProductsCount.push({
        filter_text: ref.filter_text,
        products_length: num.length,
      })
    });
    productCategories.push({ filter_header: category.filter_header, icon_image_url: category.icon_image_url, subcategories: subcategoriesProductsCount })
  });
  return productCategories;
}

const SEARCH_DELAY = 500;

function searchpFilterParse(searchParams) {
  try {
    return JSON.parse(searchParams.get("filter")) || []
  } catch (err) {
    console.log('Error parsing "filter"', err)
    return []
  }
}

export default function ProductSearch({ categories_and_filters }) {
  let [searchParams, setSearchParams] = useSearchParams();
  const lang = useLanguage();

  // let filteredCategories = useMemo(() => JSON.parse(searchParams.get("category")) || [], []);
  const [showFilters, showFiltersSet] = useState(false)
  const handleToggleClick = useCallback(() => {
    showFiltersSet(state => !state)
  }, []);

  const filterCurrentvalue = useMemo(
    () => searchpFilterParse(searchParams),
    [searchParams]
  );
  const searchCurrentValue = useMemo(() => searchParams.get("search") || "", [searchParams])
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const handleInputChange = useCallback((event, obj) => {
    const filterCurrentV = searchpFilterParse(searchParams)
    const target = event.target;
    const value = target.value;
    const updatedFilterVal = filterCurrentV.includes(value) ? filterCurrentV.filter((v) => v !== value) : [...filterCurrentV, value]
    searchParams.set('filter', JSON.stringify(updatedFilterVal))
    setSearchParams(searchParams, { preventScrollReset: true })
  }, [searchParams, setSearchParams])

  const customDebounce = useMemo(
    () =>
      debounceFunction(
        (nextValue) => {
          searchParams.set("search", nextValue)
          setSearchParams(searchParams, { preventScrollReset: true })
        },
        SEARCH_DELAY
      ),
    [searchParams, setSearchParams]
  );

  const handleChange = (event) => {
    const nextValue = event.target.value;
    setSearchValue(nextValue);
    customDebounce(nextValue);
  };

  const handleReset = useCallback(() => {
    setSearchParams({ filter: JSON.stringify([]), search: '' }, { preventScrollReset: true })
    setSearchValue("");
  }, [setSearchParams]);

  const filteredProducts = useMemo(() => filterProductsList(
    searchCurrentValue,
    filterCurrentvalue
  ), [searchCurrentValue,
    filterCurrentvalue]);

  const productCategoriesList = useMemo(() => filterProductsByCategory(
    searchCurrentValue,
    categories_and_filters
  ), [searchCurrentValue,
    categories_and_filters]);

  return (
    <>
      <div className="flex justify-center block-none-m justify-between-l center w-100-l ">
        <div className="search-input">
          <input
            className=" ml0 ma3 mr0-l pa3 db-ns w-100 w-50-m w-75-l ba"
            type="text"
            onChange={handleChange}
            value={searchValue}
            placeholder="Produkte filtern"
          />
          <Link
            to="/de/products"
            onClick={handleReset}
            className={"dib pv3 ph4 mv3  outline-0 flex btn-reset"}
          >
            <img src={reset} alt="reset" />
          </Link>
        </div>
        <button
          onClick={handleToggleClick}
          className={
            showFilters
              ? "dib pv3 ph4 bg-schuster b br2 mv3  outline-0 flex "
              : "dib pv3 ph4 b bg-schuster-inverted br2 mv3  outline-0 flex"
          }
        >
          <img
            src={showFilters ? filterInvert : filterIcon}
            className=" w1 pr3"
            alt=""
          />
          Filter
        </button>
      </div>
      <h3>
        {lang === "de" ? "Zeige" : "Displaying"}{" "}
        {filteredProducts.length}{productsList.length > filteredProducts.length ? ` von insgesamt ${productsList.length} Produkten` : ' Produkte'}
      </h3>
      {showFilters && productCategoriesList ? (
        <div className="flex flex-column flex-row-l flex-wrap justify-between mb3">
          {productCategoriesList.map((categoryDefinition, index) => (
            <div
              className={`mr0 mr1-l ml0-l w-100 ${index + 1 === productCategoriesList.length ? "" : "w-30-l"
                }`}
              style={{ flexGrow: "1" }}
              key={index}
            >
              <div className="flex flex-column ph1">
                <strong>
                  <img src={categoryDefinition.icon_image_url} className="w3 pr2 v-mid" alt={categoryDefinition.filter_header} />
                  {categoryDefinition.filter_header}
                </strong>

                <ul className="pl0 flex flex-column lh-copy flex-wrap  mw8 height-max height-99">
                  {categoryDefinition.subcategories.map((filterText, indexing) => (
                    <li className="list " key={indexing}>
                      <label className="ml3 mr1" htmlFor="filter">
                        <input
                          className="mr1 filter"
                          type="checkbox"
                          id={filterText.filter_text}
                          value={filterText.filter_text}
                          checked={
                            filterCurrentvalue.indexOf(filterText.filter_text) !== -1
                          }
                          onChange={handleInputChange}
                        />
                        <label htmlFor={filterText.filter_text}>
                          {filterText.filter_text}{" "}
                          {filterText.products_length > 0 &&
                            `(${filterText.products_length})`}
                        </label>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      ) : null}
      <section>
        <div className="flex-l flex-wrap flex-row-l justify-around">
          {filteredProducts.map((product) => (
            <ProductItem
              lang={lang}
              product={product}
              key={product.product_name}
            />
          ))}
        </div>
        {filteredProducts.length === 0 ? (
          <h2 className="tc">{lang === "de" ? "Kein Produkt gefunden" : ""}</h2>
        ) : null}
      </section>
    </>
  );
}
