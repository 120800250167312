import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-sticky-header/styles.css";
import useLanguage from "../hooks/useLanguage";
import Logo from "../images/Schuster-logo.svg";
import Hamburger from "../images/hamburger.png";
import MenuData from "../MenuData.json";

const showotherlang = false

export default function Menu() {
  const [isTop, setIsTop] = useState(true);
  const [open, setOpen] = useState(false);
  // const defaultLang = localStorage.getItem("language"); // localStorage not available in serverside rendering

  useEffect(() => {
    function handleScroll() {
      setIsTop(window.scrollY < 12);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const showMenu = () => {
    setOpen((prevState) => !prevState);
  };

  const lang = useLanguage();

  // const changeLanguage = (value) => {
  //   let pathname = window.location.pathname;
  //   pathname = pathname.substring(3);
  //   const url = `/${value}${pathname.length === 1 ? "/" : pathname}`;
  //   window.history.replaceState(null, null, url);
  // };

  return (
    <div
      style={{ borderColor: "#d1d1d1" }}
      className={
        isTop
          ? " w-100 center bg-white-90 bb db-l fixed z-9999"
          : "w-100 center bg-white bb  db-l fixed z-9999"
      }
    >
      <nav className="dt w-100 mw8 center">
        <div className="justify-between pa3 flex dn-l">
          <button
            style={{ cursor: 'pointer' }}
            className="f1 fw4 no-underline white-70 hamburger-btn"
            onClick={showMenu}
          >
            <img src={Hamburger} alt="Open menu" />
          </button>
          {showotherlang ? <div className="dn dtc-l w2 v-mid pa0 pa2-ns">
            <Link to={'/en/'}>en</Link>
          </div> : null}
          <Link to="/de/" className="dib w5 pa1 border-box">
            <img src={Logo} alt="" style={{ maxHeight: "60px" }} />
          </Link>
        </div>

        <div
          className={
            open
              ? "v-mid pl3 pr3 flex flex-column dib-l tl"
              : "v-mid pl3 pr3 dn dib-l "
          }
        >
          {MenuData.items
            .filter((i) => !i.topnavigation_hide)
            .map((refitem) => (
              <Link
                onClick={showMenu}
                to={`/${lang}/${refitem.item_slug ? `${refitem.item_slug}/` : ""
                  }`}
                className="b no-underline white-70 dib-l pv2 ph2 black hover-schuster text"
                style={{ fontSize: "16px" }}
                key={refitem.item_slug || "home"}
              >
                {refitem.item_header}
              </Link>
            ))}
          <ul className="b no-underline white-70  dib-l pv2 ph2 black text lang-list">
            {/* <li>
              <a href="/admin" className="link-decoration-style">
                Admin
              </a>
            </li> */}
            {/* <li onClick={() => changeLanguage(DEU)}>DEU</li>
            <li onClick={() => changeLanguage(ENG)}>ENG</li> */}
          </ul>
        </div>

        {showotherlang ? <div className="dn dtc-l w2 v-mid pa0 pa2-ns">
          {lang === "de" ? <Link to={'/en/'} style={{ textDecoration: 'none' }}>en</Link> :
            <Link to={'/de/'} style={{ textDecoration: 'none' }}>de</Link>}
        </div> : null}

        <div className="dn dtc-l w2 v-mid pa0 pa2-ns">
          <Link to={`/${lang}/`} className="dib w5 pa1 border-box">
            <img src={Logo} alt="" style={{ maxHeight: "60px" }} />
          </Link>
        </div>
      </nav>
    </div>
  );
}