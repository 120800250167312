import productsdata from "./productsdata.json"

const productsList = productsdata.map(item => ({
  id: item.id,
  urlpart: item.urlpart,
  product_title: item.product_title,
  title: item.title,
  ...item.fields,
}))

export default productsList
