import React from "react";
import "tachyons/css/tachyons.css";
import "../App.css";
import Header from "../components/header";
import ContactForm from "../components/ContactForm";
import useLanguage from "../hooks/useLanguage";
import Markdown from "../components/Markdown";
import AppPage from "./AppPage.json";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function YoutubeEmbed({ embedId }) {
  return (
    <a
      className="dib pv3 ph4  link bg-schuster br2 b hover-schuster-inverted-white"
      href={`https://www.youtube.com/watch?v=${embedId}`}
      target="_blank" rel="noopener noreferrer nofollow"
    >
      schuster elektronik GmbH auf Youtube
    </a>
  );
}

export default function App() {
  const lang = useLanguage();
  const {
    backgroundImage,
    header,
    headerUnderstatement,
    video_section_content,
    sections,
    vorstellung_header,
    vorstellung_item,
    vorstellung_button,
    af_heighleight_header,
    af_highlight,
    application_field_button,
    open_jobs_header,
    open_jobs,
    open_jobs_button,
    page_name,
    header_understatement,
  } = AppPage;

  return (
    <>
      <Helmet>
        <title>{page_name}</title>
        <meta name="description" content={header_understatement} />
      </Helmet>
      <div>
        <Header
          backgroundImage={backgroundImage}
          header={header}
          headerUnderstatement={headerUnderstatement}
        />
        {sections.map(({ header, header_en, content, content_en }, idx) => (
          <section className="mw8 center ph4" key={idx}>
            <h1 className="tc text-header mt5 mb5 fw1 f3 f2-ns">{lang === 'de' ? header : header_en}</h1>
            <div className="lh-copy flex-l flex-row-l flex-wrap lh-solid justify-between justify-t-c">
              <Markdown value={lang === 'de' ? content : content_en} />
            </div>
          </section>
        ))}

        <section className="mw8 center ph4">
          <h1 className="tc text-header mt5 mb5 fw1 f3 f2-ns">
            {vorstellung_header}
          </h1>

          <div className="flex-l flex-row-l pa3 pa0-ns g-2">
            {vorstellung_item.map((vr, index) => (
              <div
                className="flex flex-column items-start center flex-1-1-100"
                key={index}
              >
                <img className="h4" src={vr.vorstellung_image} alt="" />
                <div className=" m0 w-100 w-100-l">
                  <h3 className="fw2 w-bold gray-color">
                    {vr.vorstellung_header}
                  </h3>
                  <p className="text lh-copy justify-t-c">
                    {vr.vorstellung_text}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="tc mv5">
            <Link
              href=""
              className="dib pv3 ph4  link bg-schuster br2 b hover-schuster-inverted-white"
              to={`/${lang}/services/`}
            >
              {vorstellung_button}
            </Link>
          </div>
        </section>

        {video_section_content ? (
          <div className="tc">
            <section className="mw8 center pv3 ph4">
              <div className="v-content">
                <YoutubeEmbed embedId={video_section_content} />
              </div>
            </section>
          </div>
        ) : null}

        <section className="mw8 center pv3 ph4">
          <h1 className="tc text-header mt5 mb5 fw1 f3 f2-ns">
            {af_heighleight_header}
          </h1>
          <div className="flex-l flex-row-l flex-wrap lh-solid justify-between w-80 center w-100-l">
            {af_highlight.map((ApplicationField, index) => (
              <Link
                className="flex flex-column items-center mr0 mr2-l ml2-l bg-green-schuster mt3 w-30-l one-edge-shadow link "
                key={index}
                style={{ maxHeight: "50vh" }}
                to={`/${lang}/category/${ApplicationField.urlmatcher}`}
              >
                <div className="pa3 mw5">
                  <img
                    className="mb3 mt3"
                    src={ApplicationField.af_image || null}
                    alt=""
                  />
                  <div className="flex flex-column flex-wrap justify-between">
                    <h2 className="fw2 tc white f-26">
                      {ApplicationField.af_header}
                    </h2>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className="tc mv5">
            <Link
              className="dib pv3 ph4 b link bg-schuster br2 hover-schuster-inverted-white"
              to={`/${lang}/products`}
            >
              {application_field_button}
            </Link>
          </div>
        </section>

        <div id={`#jobs`}>
          <section className="mw8 center pv3 ph4">
            <h1 className="tc text-header mt5 mb5 fw1 f3 f2-ns">
              {open_jobs_header}
            </h1>
            <div className="flex flex-row flex-wrap justify-center g-2">
              {open_jobs.map((job, index) => (
                <a
                  href={job.pdf_file}
                  download
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                  className="flex flex-wrap  flex-column items-center  bg-light-gray mt3 w-100 w-40-ns"
                  key={index}
                >
                  <div className="flex-column items-center lh-copy mh2 pa3  bg-light-gray justify-center">
                    <img
                      className=" w4 h4 br-100 db center mb3"
                      src={job.job_image}
                      alt=""
                    />
                    <div className="pl3 flex-auto">
                      <p className=" db gray-color tc f-18 mt0 mb1 b">
                        {job.job_titel}
                      </p>
                      <small className="db fw6 tc link gray-color">
                        {job.job_under_titel}
                      </small>
                    </div>
                  </div>
                </a>
              ))}
            </div>
            <div className="tc mv5">
              <Link
                href=""
                className="dib pv3 ph4 b link bg-schuster br2 bn hover-schuster-inverted-white"
                to={`/${lang}/career/`}
              >
                {open_jobs_button}
              </Link>
            </div>
          </section>
        </div>

        <ContactForm />
      </div>
    </>
  );
}

// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// <div className="bg-light-gray ">
//   <section className="mw8 center pa3">
//     <div>
//       <div>
//         <Slider {...settings}>
//           {comment_item.map((comment, index) => (
//             <div key={index}>
//               <h3 className=" b mr2 gray-color f-26 tc center fw2">
//                 {comment.comment}
//               </h3>
//               <p className="mt4 tc b f-18 ">
//                 {comment.commentator}, {comment.position}
//               </p>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   </section>
// </div>
