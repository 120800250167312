import React, { useMemo } from "react";
import Header from "../components/header";
import ContactForm from "../components/ContactForm";
import ProductItem from "../components/ProductItem";
import useLanguage from "../hooks/useLanguage";

// import ProductCategoryCategoriesData from "./ProductCategoryCategoriesData.json";
import ProductsPageData from "./ProductsPage.json";

import { useParams } from "react-router-dom";
import productsList from "../productsList";
import { Helmet } from "react-helmet";

const categories_all = [];
ProductsPageData.categories_and_filters.forEach((cat) => {
  if (cat.subcategories) {
    cat.subcategories.forEach((subcat) => {
      categories_all.push(subcat);
    });
  }
});

export default function ProductCategory() {
  const { category } = useParams();
  const lang = useLanguage();

  const subcategorymatch = useMemo(
    () => categories_all.find((c) => c.urlmatcher === category),
    [category]
  );

  const {
    productfiltermatcherid,
    application_field_background,
    background_header,
    filter_text,
    background_header_text,
    af_description,
    af_description_header,
    products_header,
    page_name,
  } = subcategorymatch || {};

  const filterproducts = useMemo(
    () =>
      productsList.filter((item) => {
        return item.applicationfields.find((af) => {
          // console.log(
          //   "af.label",
          //   af,
          //   "productfiltermatcherid",
          //   productfiltermatcherid
          // );
          return af.productfiltermatcherid === productfiltermatcherid;
        });
      }),
    [productfiltermatcherid]
  );

  const title = af_description_header || filter_text
  const page_title = useMemo(() => {
    if (!subcategorymatch) {
      return page_name
    }
    return `${title} - ${page_name} -- schuster-elektronik.de`
  }, [title, page_name, subcategorymatch])
  const page_description = af_description

  if (!subcategorymatch) {
    return (
      <div style={{ padding: "150px" }}>
        Wir konnten leider keine passende Produktkategorie zu Ihrer Anfrage
        finden.
      </div>
    );
  }

  // TODO: metadata into head
  //   <meta property="og:type" content="article" />
  //   <meta name="keywords" content="banana, banana facts" />
  //   {/* https://www.conductor.com/academy/open-graph/ */}
  //   <meta property="og:image" content="https://www.contentkingapp.com/media/social-cards/og-en-contentking-schema-org@2x.png" />
  //   {locales.map((locale) => {
  //     <link rel="alternate" href="http://example.com/{locale}" hrefLang={locale} key={locale} />
  //   })}
  //   {/* inline script elements */}
  //   <script type="application/ld+json">{`
  // {
  //   "@context": "http://schema.org"
  // }
  // `}</script>

  return (
    <>
      <Helmet>
        <html lang={lang} />
        <title>{page_title}</title>
        <meta name="description" content={page_description} />
      </Helmet>
      <div>
        <div>
          {background_header && background_header.length ? (
            <Header
              backgroundImage={application_field_background}
              header={background_header}
              header_understatement={background_header_text}
            />
          ) : (
            <div className="pt5" />
          )}
          <section className="mw8 center ph4">
            <h1 className="tc text-header mt5 mb5 fw1 f3 f2-ns">
              {title}
            </h1>
            <div className="columns1 columns2 justify-t-c">
              <p className="mv0">{page_description}</p>
            </div>
          </section>

          {filterproducts && filterproducts.length ? (
            <h1 className="mw8 center tc text-header mt5 mb5 fw1 f3 f2-ns ph4">
              {products_header}
            </h1>
          ) : null}
        </div>
        <section className="mw8 center ph4">
          <div className="flex-l flex-wrap flex-row-l justify-around">
            {filterproducts.map((product, index) => (
              <ProductItem product={product} key={index} lang={lang} />
            ))}
          </div>
        </section>
        <ContactForm />
      </div>
    </>
  );
}
